.DropZone {
    display: inline-block;
    width: 100px;
    margin-left: 8px;
    padding: 8px;
    border: 2px solid #8acc73;
    border-radius: 3px;
}

.DropZone--is_empty {
    border: 2px dashed #ccc;
}
