#FileContainerModal {
    width: 600px;
}

#FileContainerModal table {
    margin: 1rem 0 2rem 0;
}

#FileContainerModal td {
    padding-right: 1rem;
}

#FileContainerModal .containerName {
    font-weight: bold;
}

#FileContainerModal th {
    padding-bottom: 1rem;
}

#FileContainerModal thead {
    font-weight: bold;
}
