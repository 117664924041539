.EquipmentSettings {
    padding-top: 4px;
}

.EquipmentSettings__Field,
.EquipmentSettings__Constant {
    display: grid;
    margin-bottom: 2px;
}

.EquipmentSettings__Field {
    grid-template-columns: 75% 25%;
}

.EquipmentSettings__Constant {
    grid-template-columns: 50% 30% 20%;
}

.EquipmentSettings__Field__Label,
.EquipmentSettings__Constant__Label {
    overflow: hidden;
}

.EquipmentSettings__NameField {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 8px;
}

.EquipmentSettings__NameField > span {
    font-weight: bold;
}

.EquipmentSettings__MainEquipment {
    overflow-y: auto;
    height: 100%;
    box-sizing: border-box;
}

.EquipmentSettings__bold {
    font-weight: bold;
}

.EquipmentSettings button {
    margin-bottom: 8px;
}

.FieldManager__title {
    font-weight: bold;
    margin-bottom: 4px;
}

.FieldManager__checkbox {
    margin-right: 4px;
}

.EquipmentSettings__Headline {
    display: flex;
    justify-content: space-between;
    height: 32px;
}

.EquipmentSettings__Headline__Name {
    max-width: 260px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
}

.EquipmentSettings__Headline i {
    display: none;
    margin-left: 8px;
}

.EquipmentSettings__Headline:hover i {
    display: inline-block;
}

.EquipmentSettings__Headline i:hover {
    color: grey;
    cursor: pointer;
}

.EquipmentSettings__icons {
    opacity: 0;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1px;
}

.EquipmentSettings__icons:hover {
    opacity: 1;
}

.EquipmentSettings__icons > span {
    margin-right: 8px;
}

.EquipmentSettings__icons > *:hover {
    color: grey;
    cursor: pointer;
}