body {
    font-family: Arial, sans-serif;
    color: #444;
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.draw2d_droppable {
    cursor: pointer;
    display: inline-block;
    padding: 2px 8px 1px 8px;
}

.draw2d_droppable:hover {
    background: #f7eede;
}

form .title {
    background: #f7eede;
    padding: 4px;
    margin: 4px 0;
}

form .inputGroup {
    padding: 4px;
}

input {
    padding: 1px 0 0 2px;
}

input, select, textarea {
    border: 1px solid #666;
}

/*Draw2d miscalculates figure position when OS scaling is other than 100%. This rule prevents the miscalculation.*/
#draw2d_canvas > svg {
    position: static!important;
}

/*Draw2d Text is used instead of Label.*/
/*However Text has no styling attributes, so this rule is needed to remove Label border.*/
rect.draw2d_shape_basic_Text {
    opacity: 0!important;
}

/* https://tympanus.net/Development/ButtonStylesInspiration/

/* Common button styles */
.button {
    /*float: left;*/
    min-width: 150px;
    max-width: 250px;
    /*display: block;*/
    /*margin: 1em;*/
    padding: 1em 2em;
    border: none;
    background: none;
    color: inherit;
    vertical-align: middle;
    position: relative;
    /*z-index: 1;*/
    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;

    /* own */
    margin: 0 4px 4px 0;
}
.button:focus {
    outline: none;
}
.button > span {
    vertical-align: middle;
    margin: 0 8px;
}

/* Text color adjustments (we could stick to the "inherit" but that does not work well in Safari) */
.bg-1 .button {
    color: #37474f;
    border-color: #37474f;
}
.bg-2 .button {
    color: #ECEFF1;
    border-color: #ECEFF1;
}
.bg-3 .button {
    color: #fff;
    border-color: #fff;
}

/* Sizes */
.button--size-s {
    font-size: 14px;
}
.button--size-m {
    font-size: 16px;
}
.button--size-l {
    font-size: 18px;
}

/* Typography and Roundedness */
.button--text-upper {
    letter-spacing: 2px;
    text-transform: uppercase;
}
.button--text-thin {
    font-weight: 300;
}
.button--text-medium {
    font-weight: 500;
}
.button--text-thick {
    font-weight: 600;
}
.button--round-s {
    border-radius: 5px;
}
.button--round-m {
    border-radius: 15px;
}
.button--round-l {
    border-radius: 40px;
}

/* Borders */
.button--border-thin {
    border: 1px solid;
}
.button--border-medium {
    border: 2px solid;
}
.button--border-thick {
    border: 3px solid;
}

/* Winona */
.button--winona {
    overflow: hidden;
    padding: 0;
    transition: border-color 0.3s, background-color 0.3s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--winona::after {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    color: #3f51b5;
    -webkit-transform: translate3d(0, 25%, 0);
    transform: translate3d(0, 25%, 0);
}
.button--winona > span {
    display: block;
}
.button--winona.button--inverted {
    color: #7986cb;
}
.button--winona.button--inverted:after {
    color: #fff;
}
.button--winona::after,
.button--winona > span {
    /*padding: 1em 2em;*/
    padding: 4px 0;
    transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--winona:hover:enabled,
.button--winona:focus:enabled {
    border-color: #3f51b5;
    background-color: rgba(63, 81, 181, 0.1);
}
.button--winona.button--inverted:hover:enabled,
.button--winona.button--inverted:focus:enabled {
    border-color: #21333C;
    background-color: #21333C;
}
.button--winona:hover:enabled::after,
.button--winona:focus:enabled::after {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.button--winona:hover:enabled > span,
.button--winona:focus:enabled > span {
    opacity: 0;
    -webkit-transform: translate3d(0, -25%, 0);
    transform: translate3d(0, -25%, 0);
}

.button--winona:disabled {
    color: #aaa;
}

#modalRoot {
    position: absolute;
    top: 0;
}

.Modal__overlay {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.Modal__window {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color: rgba(50, 50, 50, 1);
    background: rgba(255, 255, 255, 1);
    padding: 4px;
}

.Modal__close {
    color: #fff;
    width: 16px;
    height: 16px;
    text-align: center;
    margin-left: 16px;
}

.Modal__close:hover {
    cursor: pointer;
    color: rgba(175, 175, 175, 0.7);
}

.Modal__heading {
    display: flex;
    justify-content: space-between;
    background: #25496a;
    padding: 8px;
}

.Modal__title {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
}

.Modal__title img {
    display: inline-block;
    width: 20px;
    margin-right: 8px;
    vertical-align: bottom;
}

.Modal__content {
    padding: 16px 8px;
}

.EquipmentSettings {
    padding-top: 4px;
}

.EquipmentSettings__Field,
.EquipmentSettings__Constant {
    display: grid;
    margin-bottom: 2px;
}

.EquipmentSettings__Field {
    grid-template-columns: 75% 25%;
}

.EquipmentSettings__Constant {
    grid-template-columns: 50% 30% 20%;
}

.EquipmentSettings__Field__Label,
.EquipmentSettings__Constant__Label {
    overflow: hidden;
}

.EquipmentSettings__NameField {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-bottom: 8px;
}

.EquipmentSettings__NameField > span {
    font-weight: bold;
}

.EquipmentSettings__MainEquipment {
    overflow-y: auto;
    height: 100%;
    box-sizing: border-box;
}

.EquipmentSettings__bold {
    font-weight: bold;
}

.EquipmentSettings button {
    margin-bottom: 8px;
}

.FieldManager__title {
    font-weight: bold;
    margin-bottom: 4px;
}

.FieldManager__checkbox {
    margin-right: 4px;
}

.EquipmentSettings__Headline {
    display: flex;
    justify-content: space-between;
    height: 32px;
}

.EquipmentSettings__Headline__Name {
    max-width: 260px;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bold;
}

.EquipmentSettings__Headline i {
    display: none;
    margin-left: 8px;
}

.EquipmentSettings__Headline:hover i {
    display: inline-block;
}

.EquipmentSettings__Headline i:hover {
    color: grey;
    cursor: pointer;
}

.EquipmentSettings__icons {
    opacity: 0;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1px;
}

.EquipmentSettings__icons:hover {
    opacity: 1;
}

.EquipmentSettings__icons > span {
    margin-right: 8px;
}

.EquipmentSettings__icons > *:hover {
    color: grey;
    cursor: pointer;
}
.Tree {
    list-style-type: none;
}

.Tree {
    margin: 0;
    padding: 0;
}

.Tree__Caret {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    padding: 4px 0;
}

.Tree__Caret:hover {
    background: #f7eede;
}

.Tree__Caret--caretDown::before {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.Tree__Nested {
    display: none;
    padding-left: 32px;
}

.Tree__Nested--active {
    display: block;
}

.EquipmentLeaf {
    padding: 4px;
}

.EquipmentLeaf > i {
    padding-right: 8px;
}

#ThumbnailDisplay {
    max-width: 314px;
    height: 100%;
    overflow: auto;
}

#FileControl {
    display: flex;
    width: 1300px;
    height: 700px;
}

#FileControl .Files {
    overflow: auto;
    width: 700px;
    height: 673px;
}

#FileControl .Files--loading {
    background: #f7eede;
}

#FileControl .Filters {
    width: 500px;
    height: 700px;
    padding-left: 1px;
    padding-right: 16px;
    overflow: auto;
}

#FileControl .Filters ul.Tree__Nested {
    padding-top: 4px;
}

#FileControl .Upload {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
}

#FileControl .Upload input[type="file"] {
    margin-bottom: 8px;
}

#FileControl .Upload button {
    width: 150px;
    background: #fff;
}

#FileControl .Upload .ButtonGroup {
    background: #f7eede;
    padding: 4px;
    margin-bottom: 8px;
}

#FileControl .Files th {
    font-weight: bold;
}

#FileControl .Files td,
#FileControl .Files th {
    padding: 4px;
    font-size: 14px;
}

#FileControl .Files tr:nth-child(even) {
    background: #eee;
}

#FileControl .Files__Summary {
    margin-top: 14px;
}

#FileControl .title {
    padding: 2px;
    background: #f7eede;
    margin-bottom: 2px;
}

#FileControl .input {
    margin-bottom: 8px;
}

#FileControl .Submit {
    position: absolute;
    bottom: 13px;
}

/********** React tags input ************/
.react-tagsinput {
    background-color: #fff;
    border: 1px solid #ccc;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 4px;
}

.react-tagsinput--focused {
    border-color: #a5d24a;
}

.react-tagsinput-tag {
    background-color: #cde69c;
    border-radius: 2px;
    border: 1px solid #a5d24a;
    color: #638421;
    display: inline-block;
    font-family: Arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2px;
}

.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
}

.react-tagsinput-tag a::before {
    content: " \D7";
}

.react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: Arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 2px;
    margin-top: 1px;
    outline: none;
    padding: 2px;
    width: 80px;
}

#Sidebar {
    width: 350px;
    height: 100%;
    border-right: 1px solid #ccc;
    overflow-y: auto;
    box-sizing: border-box;
}

#Sidebar__Title {
    padding: 4px 6px;
    border-bottom: 1px solid #ccc;
    color: #fff;
    background: #25496a;
    font-size: 14px;
}

.WorkZone {
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.WorkZone__Tabs {
    position: relative;
    height: 18px;
    border-bottom: 1px solid #ccc;
    display: flex;
    padding-top: 4px;
    background: #f7eede;
}

.WorkZone__Tabs > span {
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    margin-left: 4px;
    padding: 2px 8px 2px;
    cursor: default;
    background: #eee;
    position: absolute;
    top: 3px;
    font-size: 14px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    transition: background .1s;
}

.WorkZone__Tabs > span.active {
    background: #fff;
    border-bottom: 1px solid #fff;
}

.WorkZone__Tabs > span:hover {
    background: #fff;
    transition: background .1s;
}

.WorkZone__Tabs > span:nth-child(2) {
    left: 66px;
}

.WorkZone__Tabs > span:nth-child(3) {
    left: 138px;
}

.WorkZone__Tabs > span:nth-child(4) {
    left: 210px;
}

.WorkZone__Tabs > span:nth-child(5) {
    left: 277px;
}

.WorkZone__Content {
    height: 100%;
    flex: 1 1;
    padding: 8px;
    overflow-y: auto;
}

.ServiceApps .button {
    display: block;
    margin-bottom: 8px;
    max-width: none;
    width: 100%;
}

.ServiceApps .react-tagsinput {
    margin-bottom: 8px;
}

#ServiceApps .Filters ul.Tree__Nested {
    padding-top: 4px;
}

.UserInputs > button {
    margin-top: 16px;
}

.UserInputs-row {
    height: 26px;
}

.UserInputs-key {
    display: inline-block;
    width: 200px;
}

/*.Designer {
    display: flex;
    height: 100%;
}*/

#draw2d_canvas {
    display: flex;
    height: 100%;
    border: 1px solid #eee;
    box-sizing: border-box;
}

.DropZone {
    display: inline-block;
    width: 100px;
    margin-left: 8px;
    padding: 8px;
    border: 2px solid #8acc73;
    border-radius: 3px;
}

.DropZone--is_empty {
    border: 2px dashed #ccc;
}

.Draggable {
    display: inline-block;
    margin-top: 8px;
    padding: 8px;
    border: 2px solid #ccc;
    border-radius: 3px;
}

.Draggable:hover {
    cursor: pointer;
    background: #f7eede;
}

.Draggable > i {
    margin-right: 8px;
}

.NewMetaItem .icons {
    margin-left: auto;
}

.NewMetaItem .icons i:first-child {
    margin-right: 8px;
}

#FileDisplay {
  height: 96%;
}

#FileDisplay .fileDetails {
  height: 100%;
  overflow: auto;
}

#FileDisplay table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
}

#FileDisplay table td {
  padding: 2px;
}

#FileDisplay .fileInfoRow.isFilteredOut {
  visibility: hidden;
}

#FileManager {
    display: flex;
    height: 600px;
}

#FileManager .filterBar {
    width: 340px;
}

#FileManager .fileDisplayContainer {
    width: 600px;
}

#FileManager .transferBar {

}

#FileManager ul.tabs {
    position: relative;
    height: 18px;
    border-bottom: 1px solid #ccc;
    display: flex;
    padding-top: 4px;
    background: #f7eede;
}

#FileManager ul.tabs > li {
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    margin-left: 4px;
    padding: 2px 8px 2px;
    cursor: default;
    background: #eee;
    position: absolute;
    top: 3px;
    font-size: 14px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    transition: background .1s;
}

#FileManager ul.tabs > li.active {
    background: #fff;
    border-bottom: 1px solid #fff;
}

#FileManager ul.tabs > li:hover {
    background: #fff;
    transition: background .1s;
}

#FileManager ul.tabs > li:nth-child(2) {
    left: 53px;
}

#FileManager ul.tabs > li:nth-child(3) {
    left: 106px;
}

#FileManager .filterBarContent {

}

.FileManager__TabContainer > * {
    margin-bottom: 8px;
}

.FileManager__TabContainer .editIcon {
    margin-left: auto;
}

.FileManager__TabContainer .editIcon:hover {
    cursor: pointer;
}

.FileManager__TabContainer .editIcon.disabled {
    color: #aaa;
    cursor: not-allowed;
}

.FileManager__TabContainer__openImageIcon {
    margin-left: 1em;
    cursor: pointer;
    color: #444;
}

.FileManager__TabContainer .sectionTitle {
    font-weight: bold;
    margin-bottom: 16px;
    word-break: break-all;
}

.FileManager__TabContainer .fileNameFilterClear {
    float: right;
}

#FileManager #FileFilter,
#FileManager #FileMeta,
#FileManager #FileApps {
    padding: 8px;
}

.FileManager__TabContainer .NewMetaInput {
    display: flex;
}

.FileManager__TabContainer .NewMetaInput > input {
    width: 130px;
    margin-right: 4px;
}

#FileManager .buttonGroup button {
    margin-top: 8px;
}

.Allocator__WorkZone {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.Allocator__WorkZone > * {
    width: 50%;
}

.Allocator__plant {
    border-right: 1px solid #ccc;
    overflow-y: auto;
}

.Allocator__plant .Tree {
    margin-top: 16px;
}

.Allocator__WorkZone li input:checked + ol {
    margin: -1em 0 0 -44px;
}

.Allocator__WorkZone li input:checked + ol > li {
    margin: 0 0 0.5em;
}

.Allocator__WorkZone h2 {
    margin-bottom: 16px;
    font-weight: bold;
}

.Allocator__fileList {
    margin-top: 16px;
}

.Allocator__fileRow {
    margin-bottom: 16px;
}

.Allocator__fileRow button {
    float: right;
}

.Allocator__WorkZone table {
    border-collapse: separate;
    border-collapse: initial;
    border-spacing: 8px;
}

.Allocator__WorkZone table th {
    font-weight: bold;
}

.Allocator__fields td {
    white-space: nowrap;
}

.Allocator__data {
    overflow-y: auto;
    padding-top: 16px;
    padding-left: 16px;
}

.DataSourceControl {
    padding: 12px;
}

.DataSourceControl button {
    margin-bottom: 8px;
}

.DataSourceControl hr {
    margin-top: 16px;
}

.DataSourceControl .Draggable {
    margin-bottom: 8px;
}

.AllocationTree__Icon {
    display: inline-block;
    width: 32px;
    text-align: center;
}

.AllocationTree .DropZone {
    display: inline-block;
    padding: 1px 4px;
    margin: 0;
    margin-right: 8px;
    width: 80px;
}

.AllocationTree .DropZone > span {
    width: 64px;
    font-size: 12px;
    vertical-align: middle;
}

.AllocationTree .DropZone i {
    color: #ccc;
}

.AllocationTree .Tree__Nested {
    padding-left: 26px;
}

.AllocationTree__Icon--Variable {
    margin-left: 16px;
}

.AllocationTree__Variable {
    display: inline-block;
    padding: 4px;
}

.AllocationTree__Variable select {
    margin-right: 8px;
}

.AllocationTree__DropZoneLabel {
    display: inline-block;
    width: 60px;
    text-overflow: ellipsis;
    margin-left: 4px;
    vertical-align: text-top;
    overflow: hidden;
}

#DataSourceAdder {
    width: 600px;
    height: 400px;
}

.DataSourceAdder__filter > * {
    margin-bottom: 8px;
}

#DataSourceAdder > ul {
    border: 1px solid #ccc;
    position: absolute;
    top: 142px;
    right: 8px;
    bottom: 8px;
    left: 8px;
    padding: 8px 4px;
    overflow: auto;
}

#DataSourceAdder li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 22px;
}

#DataSourceAdder li button {
    display: none;
}

#DataSourceAdder li:hover button {
    display: block;
}

.AllocationTree__Images {
    display: inline-block;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.AllocationTree .deleteIcon {
    margin-left: 8px;
}

.AllocationTree .deleteIcon:hover {
    cursor: pointer;
}

.AllocationTree .support {
    margin-bottom: 4px;
}

.DataSourceDrag__PreviewToggle {
    cursor: pointer;
    margin-bottom: 8px;
}

.DataSourceDrag__PreviewToggle:hover {
    background: #f7eede;
}

.DataSourceDrag__Preview {
    overflow: auto;
    max-width: 500px;
    font-size: 14px;
}

.DataSourceDrag__Title {
    margin-bottom: 16px;
    font-weight: bold;
}

.Analyser__Content {
    display: flex;
    justify-content: space-around;
    height: 100%;
    padding: 64px;
    box-sizing: border-box;
}

.Analyser__Sidebar {
    padding: 12px;
}

.Analyser__Content img {
    opacity: 0.1;
    height: 100%;
}

.Analyser__Filter {
    margin-top: 16px;
}

.Analyser__JobFilter {
    width: 201px;
    margin-top: 4px;
}

.Analyser__Sidebar input.react-tagsinput-input {
    font-size: 100%;
    width: 128px;
    margin: 0;
    padding: 3px 0 0 3px;
}

.Analyser__Sidebar .react-tagsinput {
    padding: 0;
}

#Control .controllerEquipment {
    border: 1px solid #9d9d9d;
    padding: 8px;
    width: 400px;
    margin-bottom: 8px;
}

#Control .endpoint {
    margin-bottom: 8px;
}

.ImagePreviewContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  grid-auto-rows: -webkit-max-content;
  grid-auto-rows: max-content;
  grid-gap: 8px;
  gap: 8px;
  height: 82vh;
  overflow: auto;
}

.ImagePreviewContainer .thumb {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  border: 2px solid #fff;
  padding: 2px;
  height: 340px;
}

.ImagePreviewContainer .thumb.selected {
  background: #96cdff;
}

.ImagePreviewContainer .thumb .filename {
  font-family: monospace;
  font-size: 13px;
  height: 40px;
  max-width: 300px;
  overflow: hidden;
  word-break: break-all;
  text-align: center;
}

.fileNameContainer {
  height: 40px;
}

.ImagePreviewContainer .thumb > img {
  margin: auto;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.ImagePreviewContainer > .thumb.isFilteredOut {
  visibility: hidden;
}

#PaginationContainer {
  height: 40px;
  margin: 8px auto;
  overflow-x: auto;
  max-width: 800px;
  display: flex;
  justify-content: center;
}

#Pagination .pageItem {
  display: inline;
  padding: 0 4px;
  font-weight: bold;
  cursor: pointer;
  color: #0077cc;
}

#Pagination .pageItem:hover {
  background: #ccc;
}

#Pagination .pageItem.activePage {
  color: #444;
}

#Pagination .pageItem.activePage:hover {
  background: #fff;
  cursor: default;
}

.ImageMeta .inputs {
  display: flex;
  justify-content: space-between;
}

.ImageMeta .inputs input {
  width: 140px;
}

.ImageManager__TabContainer > * {
  margin-bottom: 8px;
}

.ImageManager__TabContainer .editIcon {
  margin-left: auto;
}

.ImageManager__TabContainer .editIcon:hover {
  cursor: pointer;
}

.ImageManager__TabContainer .editIcon.disabled {
  color: #aaa;
  cursor: not-allowed;
}

.ImageManager__TabContainer__openImageIcon {
  margin-left: 1em;
  cursor: pointer;
  color: #444;
}

.ImageManager__MetaList {
  margin-bottom: 16px;
}

.ImageManager__MetaList .metaItem {
  display: flex;
  margin-bottom: 8px;
}

.ImageManager__MetaList .metaItem .metaKey {
  width: 140px;
}

.ImageManager__TabContainer .NewMetaInput {
  display: flex;
}

.ImageManager__TabContainer .NewMetaInput > input {
  width: 130px;
  margin-right: 4px;
}

.ImageManager__TabContainer .sectionTitle {
  font-weight: bold;
  margin-bottom: 16px;
  word-break: break-all;
}

.ImageManager__TabContainer .fileNameFilterClear {
  float: right;
}

.FilterQueryTextarea,
#FileNameFilter,
.ImageManager__Editor {
  margin-bottom: 4px;
}

#FileNameFilter > textarea {
  width: 100%;
}

.FilterQueryTextarea,
#FileNameFilter > textarea {
  min-width: 324px;
  max-width: 324px;
  min-height: 80px;
}

#ImageFilter > h6 {
  /*margin removed, because the close icon below takes place, too*/
  margin-bottom: 0;
}

.imageAppRadioContainer {
  margin-bottom: 16px;
}

.withLabel {
  margin-right: 8px;
}

.Menu {
    position: relative;
    padding: 4px 5px;
    background: #eee;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
}

.Menu__Content {
    position: absolute;
    top: 22px;
    background: #eee;
    border: 1px solid #ccc;
    padding: 4px;
    z-index: 1;
    min-width: 128px;
}

.Menu__Content > div > div {
    padding: 4px 8px;
    cursor: default;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.Menu__Content > div > div:hover {
    background: #d5cbc0;
}

.MenuTitle {
    padding: 0 8px;
    cursor: default;
    border: 1px solid #eee;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.MenuTitle:hover {
    background: #f7eede;
    border: 1px solid #bfb5aa;
}

.Menu__jobTitle,
.Menu__userName {
    float: right;
    margin-right: 8px;
}

#NewJobModal {
    width: 1200px;
    height: 500px;
    display: flex;
}

#NewJobModal__Settings {
    width: 300px;
}

#NewJobModal__Settings input {
    margin-bottom: 4px;
}

#NewJobModal__SavedJobs {
    display: flex;
    flex-direction: column;
    width: 550px;
}

#NewJobModal__SavedJobs table {
    width: 100%;
}

#NewJobModal__SavedJobs table tr {
    padding: 0 4px;
}

#NewJobModal__SavedJobs table td {
    padding: 2px;
}

#NewJobModal__SavedJobs table th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    font-weight: bold;
}

#NewJobModal__SavedJobs tbody tr:hover {
    cursor: pointer;
}

.NewJobModal__SavedJobItem--selected {
    background: #f7eede;
}

#NewJobModal h2 {
    font-weight: bold;
    margin-bottom: 4px;
}

#NewJobModal__Buttons {
    position: absolute;
    bottom: 20px;
}

#NewJobModal__Checkboxes {
    position: absolute;
    bottom: 64px;
}

#NewJobModal__SavedJobs div.tableContainer {
    position: absolute;
    height: 450px;
    width: 900px;
    overflow-y: auto;
    margin-top: 36px;
}

#NewJobModal__SavedJobs .jobs-header h2 {
    display: inline-block;
}

#NewJobModal__SavedJobs .jobs-header input {
    position: absolute;
    right: 10px;
    width: 275px;
}

#FileContainerModal {
    width: 600px;
}

#FileContainerModal table {
    margin: 1rem 0 2rem 0;
}

#FileContainerModal td {
    padding-right: 1rem;
}

#FileContainerModal .containerName {
    font-weight: bold;
}

#FileContainerModal th {
    padding-bottom: 1rem;
}

#FileContainerModal thead {
    font-weight: bold;
}

.AnalysisSelectModal {
    display: flex;
    flex-direction: column;
    width: 800px;
    height: 600px;
}

.AnalysisSelectModal__analyses {
    margin: 8px 0;
    flex-grow: 1;
    overflow-y: auto;
}

.AnalysisSelectModal__control {
    padding-left: 8px;
}

.AnalysisSelectModal input[type="checkbox"] {
    margin-right: 8px;
}

/*.AnalysisSelectModal__analyses {
    display: flex;
    margin-bottom: 16px;
}

.AnalysisSelectModal__analyses__analysis {
    margin-left: 16px;
}

.AnalysisSelectModal__analyses__analysis label {
    cursor: pointer;
}

.AnalysisSelectModal .columnHeader {
    font-weight: bold;
    margin: 16px 0;
}*/

.FileModal {
    display: flex;
    width: 800px;
    height: 400px;
}

.FileModal > * {
    width: 50%;
}

.FileModal__Settings {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 24px 24px 24px 80px 24px 24px;
}

.FileModal__Settings__FileSelect {
    grid-column: 1/3;
}

.FileModal__UserFiles {

}

.FileModal__Settings__UploadButton, .FileModal__Settings__Progress {
    grid-column: 1/3;
}

#DBQueryEditor {
    width: 500px;
}

#DBQueryEditor__DB {
    margin-bottom: 16px;
}

#DBQueryEditor button {
    float: right;
}

#DBQueryEditor__QueryStrings > table {
    margin-top: 16px;
}

.ShareJob {
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 550px;
}

.ShareJob > .tableContainer {
    position: absolute;
    height: 500px;
    width: 600px;
    overflow-y: auto;
    margin-top: 36px;
}

.ShareJob table {
    width: 100%;
}

.ShareJob table tr {
    padding: 0 4px;
}

.ShareJob table td {
    padding: 2px;
}

.ShareJob table th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    font-weight: bold;
}

.ShareJob ul.userList {
    position: fixed;
    background: white;
    border: 1px solid #333;
    padding: 4px;
}

.ShareJob ul.userList > li {
    display: flex;
    justify-content: flex-start;
    padding: 2px;
}

.ShareJob > tr.title > th {
    font-weight: bold;
}

.ShareJob input {
    float: right;
    margin-right: 4px;
}

.ShareJob ul {
    box-shadow: 0 0 10px -2px #000000;
}

.MainPage {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.MainPage__Content {
    height: 100%;
    display: flex;
}

.SidebarContent {
    padding: 12px;
}

.App {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.App__Head {
    background: #25496a;
    height: 32px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.App__Head img {
    height: 32px;
}

.App__Head img:first-child {
    height: 22px;
    margin-bottom: 3px;
    padding: 1px;
}

.App__User {
    color: #fff;
}

.App__User > i {
    margin-right: 8px;
    margin-top: 2px;
    vertical-align: middle;
}

.App__User > div {
    float: right;
    padding-top: 4px;
}

#AuthContainer {
    display: flex;
    justify-content: center;
    height: 100%;
}

#AuthText {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

#SwitchSitesButton {
    color: white;
    margin-right: 12px;
}

#SwitchSitesButton:hover {
    cursor: pointer;
    color: #ccc;
}

#SwitchSitesPopup {
    width: 300px;
    height: 114px;
    position: fixed;
    background: white;
    z-index: 1;
    top: 28px;
    border: 1px solid #ccc;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.64);
}

#SwitchSitesPopup > *:nth-child(1) {
    height: 40px;
}

#SwitchSitesPopup a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
}

#SwitchSitesPopup li {
    margin-bottom: 8px;
    padding: 8px;
}

#SwitchSitesPopup li:hover {
    background: #ccc;
}

#SwitchSitesPopup i {
    margin-right: 8px;
}

