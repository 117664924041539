.FileModal {
    display: flex;
    width: 800px;
    height: 400px;
}

.FileModal > * {
    width: 50%;
}

.FileModal__Settings {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 24px 24px 24px 80px 24px 24px;
}

.FileModal__Settings__FileSelect {
    grid-column: 1/3;
}

.FileModal__UserFiles {

}

.FileModal__Settings__UploadButton, .FileModal__Settings__Progress {
    grid-column: 1/3;
}
