.ImagePreviewContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  grid-auto-rows: max-content;
  gap: 8px;
  height: 82vh;
  overflow: auto;
}

.ImagePreviewContainer .thumb {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  border: 2px solid #fff;
  padding: 2px;
  height: 340px;
}

.ImagePreviewContainer .thumb.selected {
  background: #96cdff;
}

.ImagePreviewContainer .thumb .filename {
  font-family: monospace;
  font-size: 13px;
  height: 40px;
  max-width: 300px;
  overflow: hidden;
  word-break: break-all;
  text-align: center;
}

.fileNameContainer {
  height: 40px;
}

.ImagePreviewContainer .thumb > img {
  margin: auto;
  user-select: none;
}

.ImagePreviewContainer > .thumb.isFilteredOut {
  visibility: hidden;
}

#PaginationContainer {
  height: 40px;
  margin: 8px auto;
  overflow-x: auto;
  max-width: 800px;
  display: flex;
  justify-content: center;
}

#Pagination .pageItem {
  display: inline;
  padding: 0 4px;
  font-weight: bold;
  cursor: pointer;
  color: #0077cc;
}

#Pagination .pageItem:hover {
  background: #ccc;
}

#Pagination .pageItem.activePage {
  color: #444;
}

#Pagination .pageItem.activePage:hover {
  background: #fff;
  cursor: default;
}
