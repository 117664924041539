.ServiceApps .button {
    display: block;
    margin-bottom: 8px;
    max-width: none;
    width: 100%;
}

.ServiceApps .react-tagsinput {
    margin-bottom: 8px;
}

#ServiceApps .Filters ul.Tree__Nested {
    padding-top: 4px;
}

.UserInputs > button {
    margin-top: 16px;
}

.UserInputs-row {
    height: 26px;
}

.UserInputs-key {
    display: inline-block;
    width: 200px;
}
