#FileDisplay {
  height: 96%;
}

#FileDisplay .fileDetails {
  height: 100%;
  overflow: auto;
}

#FileDisplay table th {
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
}

#FileDisplay table td {
  padding: 2px;
}

#FileDisplay .fileInfoRow.isFilteredOut {
  visibility: hidden;
}
