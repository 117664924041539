.ImageMeta .inputs {
  display: flex;
  justify-content: space-between;
}

.ImageMeta .inputs input {
  width: 140px;
}

.ImageManager__TabContainer > * {
  margin-bottom: 8px;
}

.ImageManager__TabContainer .editIcon {
  margin-left: auto;
}

.ImageManager__TabContainer .editIcon:hover {
  cursor: pointer;
}

.ImageManager__TabContainer .editIcon.disabled {
  color: #aaa;
  cursor: not-allowed;
}

.ImageManager__TabContainer__openImageIcon {
  margin-left: 1em;
  cursor: pointer;
  color: #444;
}

.ImageManager__MetaList {
  margin-bottom: 16px;
}

.ImageManager__MetaList .metaItem {
  display: flex;
  margin-bottom: 8px;
}

.ImageManager__MetaList .metaItem .metaKey {
  width: 140px;
}

.ImageManager__TabContainer .NewMetaInput {
  display: flex;
}

.ImageManager__TabContainer .NewMetaInput > input {
  width: 130px;
  margin-right: 4px;
}

.ImageManager__TabContainer .sectionTitle {
  font-weight: bold;
  margin-bottom: 16px;
  word-break: break-all;
}

.ImageManager__TabContainer .fileNameFilterClear {
  float: right;
}

.FilterQueryTextarea,
#FileNameFilter,
.ImageManager__Editor {
  margin-bottom: 4px;
}

#FileNameFilter > textarea {
  width: 100%;
}

.FilterQueryTextarea,
#FileNameFilter > textarea {
  min-width: 324px;
  max-width: 324px;
  min-height: 80px;
}

#ImageFilter > h6 {
  /*margin removed, because the close icon below takes place, too*/
  margin-bottom: 0;
}

.imageAppRadioContainer {
  margin-bottom: 16px;
}

.withLabel {
  margin-right: 8px;
}
