#modalRoot {
    position: absolute;
    top: 0;
}

.Modal__overlay {
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.Modal__window {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba(50, 50, 50, 1);
    background: rgba(255, 255, 255, 1);
    padding: 4px;
}

.Modal__close {
    color: #fff;
    width: 16px;
    height: 16px;
    text-align: center;
    margin-left: 16px;
}

.Modal__close:hover {
    cursor: pointer;
    color: rgba(175, 175, 175, 0.7);
}

.Modal__heading {
    display: flex;
    justify-content: space-between;
    background: #25496a;
    padding: 8px;
}

.Modal__title {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
}

.Modal__title img {
    display: inline-block;
    width: 20px;
    margin-right: 8px;
    vertical-align: bottom;
}

.Modal__content {
    padding: 16px 8px;
}
