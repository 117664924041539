.ShareJob {
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 550px;
}

.ShareJob > .tableContainer {
    position: absolute;
    height: 500px;
    width: 600px;
    overflow-y: auto;
    margin-top: 36px;
}

.ShareJob table {
    width: 100%;
}

.ShareJob table tr {
    padding: 0 4px;
}

.ShareJob table td {
    padding: 2px;
}

.ShareJob table th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    font-weight: bold;
}

.ShareJob ul.userList {
    position: fixed;
    background: white;
    border: 1px solid #333;
    padding: 4px;
}

.ShareJob ul.userList > li {
    display: flex;
    justify-content: flex-start;
    padding: 2px;
}

.ShareJob > tr.title > th {
    font-weight: bold;
}

.ShareJob input {
    float: right;
    margin-right: 4px;
}

.ShareJob ul {
    box-shadow: 0 0 10px -2px #000000;
}
