/*.Designer {
    display: flex;
    height: 100%;
}*/

#draw2d_canvas {
    display: flex;
    height: 100%;
    border: 1px solid #eee;
    box-sizing: border-box;
}
