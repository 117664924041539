.Tree {
    list-style-type: none;
}

.Tree {
    margin: 0;
    padding: 0;
}

.Tree__Caret {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    user-select: none;
    padding: 4px 0;
}

.Tree__Caret:hover {
    background: #f7eede;
}

.Tree__Caret--caretDown::before {
    transform: rotate(90deg);
}

.Tree__Nested {
    display: none;
    padding-left: 32px;
}

.Tree__Nested--active {
    display: block;
}
