.Menu {
    position: relative;
    padding: 4px 5px;
    background: #eee;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
}

.Menu__Content {
    position: absolute;
    top: 22px;
    background: #eee;
    border: 1px solid #ccc;
    padding: 4px;
    z-index: 1;
    min-width: 128px;
}

.Menu__Content > div > div {
    padding: 4px 8px;
    cursor: default;
    user-select: none;
}

.Menu__Content > div > div:hover {
    background: #d5cbc0;
}

.MenuTitle {
    padding: 0 8px;
    cursor: default;
    border: 1px solid #eee;
    user-select: none;
}

.MenuTitle:hover {
    background: #f7eede;
    border: 1px solid #bfb5aa;
}

.Menu__jobTitle,
.Menu__userName {
    float: right;
    margin-right: 8px;
}
