.Allocator__WorkZone {
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.Allocator__WorkZone > * {
    width: 50%;
}

.Allocator__plant {
    border-right: 1px solid #ccc;
    overflow-y: auto;
}

.Allocator__plant .Tree {
    margin-top: 16px;
}

.Allocator__WorkZone li input:checked + ol {
    margin: -1em 0 0 -44px;
}

.Allocator__WorkZone li input:checked + ol > li {
    margin: 0 0 0.5em;
}

.Allocator__WorkZone h2 {
    margin-bottom: 16px;
    font-weight: bold;
}

.Allocator__fileList {
    margin-top: 16px;
}

.Allocator__fileRow {
    margin-bottom: 16px;
}

.Allocator__fileRow button {
    float: right;
}

.Allocator__WorkZone table {
    border-collapse: initial;
    border-spacing: 8px;
}

.Allocator__WorkZone table th {
    font-weight: bold;
}

.Allocator__fields td {
    white-space: nowrap;
}

.Allocator__data {
    overflow-y: auto;
    padding-top: 16px;
    padding-left: 16px;
}

.DataSourceControl {
    padding: 12px;
}

.DataSourceControl button {
    margin-bottom: 8px;
}

.DataSourceControl hr {
    margin-top: 16px;
}

.DataSourceControl .Draggable {
    margin-bottom: 8px;
}

.AllocationTree__Icon {
    display: inline-block;
    width: 32px;
    text-align: center;
}

.AllocationTree .DropZone {
    display: inline-block;
    padding: 1px 4px;
    margin: 0;
    margin-right: 8px;
    width: 80px;
}

.AllocationTree .DropZone > span {
    width: 64px;
    font-size: 12px;
    vertical-align: middle;
}

.AllocationTree .DropZone i {
    color: #ccc;
}

.AllocationTree .Tree__Nested {
    padding-left: 26px;
}

.AllocationTree__Icon--Variable {
    margin-left: 16px;
}

.AllocationTree__Variable {
    display: inline-block;
    padding: 4px;
}

.AllocationTree__Variable select {
    margin-right: 8px;
}

.AllocationTree__DropZoneLabel {
    display: inline-block;
    width: 60px;
    text-overflow: ellipsis;
    margin-left: 4px;
    vertical-align: text-top;
    overflow: hidden;
}

#DataSourceAdder {
    width: 600px;
    height: 400px;
}

.DataSourceAdder__filter > * {
    margin-bottom: 8px;
}

#DataSourceAdder > ul {
    border: 1px solid #ccc;
    position: absolute;
    top: 142px;
    right: 8px;
    bottom: 8px;
    left: 8px;
    padding: 8px 4px;
    overflow: auto;
}

#DataSourceAdder li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 22px;
}

#DataSourceAdder li button {
    display: none;
}

#DataSourceAdder li:hover button {
    display: block;
}

.AllocationTree__Images {
    display: inline-block;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.AllocationTree .deleteIcon {
    margin-left: 8px;
}

.AllocationTree .deleteIcon:hover {
    cursor: pointer;
}

.AllocationTree .support {
    margin-bottom: 4px;
}
