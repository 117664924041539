.DataSourceDrag__PreviewToggle {
    cursor: pointer;
    margin-bottom: 8px;
}

.DataSourceDrag__PreviewToggle:hover {
    background: #f7eede;
}

.DataSourceDrag__Preview {
    overflow: auto;
    max-width: 500px;
    font-size: 14px;
}

.DataSourceDrag__Title {
    margin-bottom: 16px;
    font-weight: bold;
}
