.WorkZone {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.WorkZone__Tabs {
    position: relative;
    height: 18px;
    border-bottom: 1px solid #ccc;
    display: flex;
    padding-top: 4px;
    background: #f7eede;
}

.WorkZone__Tabs > span {
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    margin-left: 4px;
    padding: 2px 8px 2px;
    cursor: default;
    background: #eee;
    position: absolute;
    top: 3px;
    font-size: 14px;
    user-select: none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    transition: background .1s;
}

.WorkZone__Tabs > span.active {
    background: #fff;
    border-bottom: 1px solid #fff;
}

.WorkZone__Tabs > span:hover {
    background: #fff;
    transition: background .1s;
}

.WorkZone__Tabs > span:nth-child(2) {
    left: 66px;
}

.WorkZone__Tabs > span:nth-child(3) {
    left: 138px;
}

.WorkZone__Tabs > span:nth-child(4) {
    left: 210px;
}

.WorkZone__Tabs > span:nth-child(5) {
    left: 277px;
}

.WorkZone__Content {
    height: 100%;
    flex: 1;
    padding: 8px;
    overflow-y: auto;
}
