.App {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.App__Head {
    background: #25496a;
    height: 32px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.App__Head img {
    height: 32px;
}

.App__Head img:first-child {
    height: 22px;
    margin-bottom: 3px;
    padding: 1px;
}

.App__User {
    color: #fff;
}

.App__User > i {
    margin-right: 8px;
    margin-top: 2px;
    vertical-align: middle;
}

.App__User > div {
    float: right;
    padding-top: 4px;
}

#AuthContainer {
    display: flex;
    justify-content: center;
    height: 100%;
}

#AuthText {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

#SwitchSitesButton {
    color: white;
    margin-right: 12px;
}

#SwitchSitesButton:hover {
    cursor: pointer;
    color: #ccc;
}

#SwitchSitesPopup {
    width: 300px;
    height: 114px;
    position: fixed;
    background: white;
    z-index: 1;
    top: 28px;
    border: 1px solid #ccc;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.64);
}

#SwitchSitesPopup > *:nth-child(1) {
    height: 40px;
}

#SwitchSitesPopup a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
}

#SwitchSitesPopup li {
    margin-bottom: 8px;
    padding: 8px;
}

#SwitchSitesPopup li:hover {
    background: #ccc;
}

#SwitchSitesPopup i {
    margin-right: 8px;
}
