#FileControl {
    display: flex;
    width: 1300px;
    height: 700px;
}

#FileControl .Files {
    overflow: auto;
    width: 700px;
    height: 673px;
}

#FileControl .Files--loading {
    background: #f7eede;
}

#FileControl .Filters {
    width: 500px;
    height: 700px;
    padding-left: 1px;
    padding-right: 16px;
    overflow: auto;
}

#FileControl .Filters ul.Tree__Nested {
    padding-top: 4px;
}

#FileControl .Upload {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
}

#FileControl .Upload input[type="file"] {
    margin-bottom: 8px;
}

#FileControl .Upload button {
    width: 150px;
    background: #fff;
}

#FileControl .Upload .ButtonGroup {
    background: #f7eede;
    padding: 4px;
    margin-bottom: 8px;
}

#FileControl .Files th {
    font-weight: bold;
}

#FileControl .Files td,
#FileControl .Files th {
    padding: 4px;
    font-size: 14px;
}

#FileControl .Files tr:nth-child(even) {
    background: #eee;
}

#FileControl .Files__Summary {
    margin-top: 14px;
}

#FileControl .title {
    padding: 2px;
    background: #f7eede;
    margin-bottom: 2px;
}

#FileControl .input {
    margin-bottom: 8px;
}

#FileControl .Submit {
    position: absolute;
    bottom: 13px;
}

/********** React tags input ************/
.react-tagsinput {
    background-color: #fff;
    border: 1px solid #ccc;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 4px;
}

.react-tagsinput--focused {
    border-color: #a5d24a;
}

.react-tagsinput-tag {
    background-color: #cde69c;
    border-radius: 2px;
    border: 1px solid #a5d24a;
    color: #638421;
    display: inline-block;
    font-family: Arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2px;
}

.react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
}

.react-tagsinput-tag a::before {
    content: " \D7";
}

.react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #777;
    font-family: Arial, sans-serif;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 2px;
    margin-top: 1px;
    outline: none;
    padding: 2px;
    width: 80px;
}
