.Analyser__Content {
    display: flex;
    justify-content: space-around;
    height: 100%;
    padding: 64px;
    box-sizing: border-box;
}

.Analyser__Sidebar {
    padding: 12px;
}

.Analyser__Content img {
    opacity: 0.1;
    height: 100%;
}

.Analyser__Filter {
    margin-top: 16px;
}

.Analyser__JobFilter {
    width: 201px;
    margin-top: 4px;
}

.Analyser__Sidebar input.react-tagsinput-input {
    font-size: 100%;
    width: 128px;
    margin: 0;
    padding: 3px 0 0 3px;
}

.Analyser__Sidebar .react-tagsinput {
    padding: 0;
}
