.Draggable {
    display: inline-block;
    margin-top: 8px;
    padding: 8px;
    border: 2px solid #ccc;
    border-radius: 3px;
}

.Draggable:hover {
    cursor: pointer;
    background: #f7eede;
}

.Draggable > i {
    margin-right: 8px;
}
