#Sidebar {
    width: 350px;
    height: 100%;
    border-right: 1px solid #ccc;
    overflow-y: auto;
    box-sizing: border-box;
}

#Sidebar__Title {
    padding: 4px 6px;
    border-bottom: 1px solid #ccc;
    color: #fff;
    background: #25496a;
    font-size: 14px;
}
