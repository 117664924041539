#NewJobModal {
    width: 1200px;
    height: 500px;
    display: flex;
}

#NewJobModal__Settings {
    width: 300px;
}

#NewJobModal__Settings input {
    margin-bottom: 4px;
}

#NewJobModal__SavedJobs {
    display: flex;
    flex-direction: column;
    width: 550px;
}

#NewJobModal__SavedJobs table {
    width: 100%;
}

#NewJobModal__SavedJobs table tr {
    padding: 0 4px;
}

#NewJobModal__SavedJobs table td {
    padding: 2px;
}

#NewJobModal__SavedJobs table th {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    font-weight: bold;
}

#NewJobModal__SavedJobs tbody tr:hover {
    cursor: pointer;
}

.NewJobModal__SavedJobItem--selected {
    background: #f7eede;
}

#NewJobModal h2 {
    font-weight: bold;
    margin-bottom: 4px;
}

#NewJobModal__Buttons {
    position: absolute;
    bottom: 20px;
}

#NewJobModal__Checkboxes {
    position: absolute;
    bottom: 64px;
}

#NewJobModal__SavedJobs div.tableContainer {
    position: absolute;
    height: 450px;
    width: 900px;
    overflow-y: auto;
    margin-top: 36px;
}

#NewJobModal__SavedJobs .jobs-header h2 {
    display: inline-block;
}

#NewJobModal__SavedJobs .jobs-header input {
    position: absolute;
    right: 10px;
    width: 275px;
}
