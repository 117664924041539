body {
    font-family: Arial, sans-serif;
    color: #444;
}

#root {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
}

.draw2d_droppable {
    cursor: pointer;
    display: inline-block;
    padding: 2px 8px 1px 8px;
}

.draw2d_droppable:hover {
    background: #f7eede;
}

form .title {
    background: #f7eede;
    padding: 4px;
    margin: 4px 0;
}

form .inputGroup {
    padding: 4px;
}

input {
    padding: 1px 0 0 2px;
}

input, select, textarea {
    border: 1px solid #666;
}

/*Draw2d miscalculates figure position when OS scaling is other than 100%. This rule prevents the miscalculation.*/
#draw2d_canvas > svg {
    position: static!important;
}

/*Draw2d Text is used instead of Label.*/
/*However Text has no styling attributes, so this rule is needed to remove Label border.*/
rect.draw2d_shape_basic_Text {
    opacity: 0!important;
}
