#DBQueryEditor {
    width: 500px;
}

#DBQueryEditor__DB {
    margin-bottom: 16px;
}

#DBQueryEditor button {
    float: right;
}

#DBQueryEditor__QueryStrings > table {
    margin-top: 16px;
}
