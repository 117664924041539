#FileManager {
    display: flex;
    height: 600px;
}

#FileManager .filterBar {
    width: 340px;
}

#FileManager .fileDisplayContainer {
    width: 600px;
}

#FileManager .transferBar {

}

#FileManager ul.tabs {
    position: relative;
    height: 18px;
    border-bottom: 1px solid #ccc;
    display: flex;
    padding-top: 4px;
    background: #f7eede;
}

#FileManager ul.tabs > li {
    border-top: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;
    margin-left: 4px;
    padding: 2px 8px 2px;
    cursor: default;
    background: #eee;
    position: absolute;
    top: 3px;
    font-size: 14px;
    user-select: none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    transition: background .1s;
}

#FileManager ul.tabs > li.active {
    background: #fff;
    border-bottom: 1px solid #fff;
}

#FileManager ul.tabs > li:hover {
    background: #fff;
    transition: background .1s;
}

#FileManager ul.tabs > li:nth-child(2) {
    left: 53px;
}

#FileManager ul.tabs > li:nth-child(3) {
    left: 106px;
}

#FileManager .filterBarContent {

}

.FileManager__TabContainer > * {
    margin-bottom: 8px;
}

.FileManager__TabContainer .editIcon {
    margin-left: auto;
}

.FileManager__TabContainer .editIcon:hover {
    cursor: pointer;
}

.FileManager__TabContainer .editIcon.disabled {
    color: #aaa;
    cursor: not-allowed;
}

.FileManager__TabContainer__openImageIcon {
    margin-left: 1em;
    cursor: pointer;
    color: #444;
}

.FileManager__TabContainer .sectionTitle {
    font-weight: bold;
    margin-bottom: 16px;
    word-break: break-all;
}

.FileManager__TabContainer .fileNameFilterClear {
    float: right;
}

#FileManager #FileFilter,
#FileManager #FileMeta,
#FileManager #FileApps {
    padding: 8px;
}

.FileManager__TabContainer .NewMetaInput {
    display: flex;
}

.FileManager__TabContainer .NewMetaInput > input {
    width: 130px;
    margin-right: 4px;
}

#FileManager .buttonGroup button {
    margin-top: 8px;
}
