/* https://tympanus.net/Development/ButtonStylesInspiration/

/* Common button styles */
.button {
    /*float: left;*/
    min-width: 150px;
    max-width: 250px;
    /*display: block;*/
    /*margin: 1em;*/
    padding: 1em 2em;
    border: none;
    background: none;
    color: inherit;
    vertical-align: middle;
    position: relative;
    /*z-index: 1;*/
    -webkit-backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;

    /* own */
    margin: 0 4px 4px 0;
}
.button:focus {
    outline: none;
}
.button > span {
    vertical-align: middle;
    margin: 0 8px;
}

/* Text color adjustments (we could stick to the "inherit" but that does not work well in Safari) */
.bg-1 .button {
    color: #37474f;
    border-color: #37474f;
}
.bg-2 .button {
    color: #ECEFF1;
    border-color: #ECEFF1;
}
.bg-3 .button {
    color: #fff;
    border-color: #fff;
}

/* Sizes */
.button--size-s {
    font-size: 14px;
}
.button--size-m {
    font-size: 16px;
}
.button--size-l {
    font-size: 18px;
}

/* Typography and Roundedness */
.button--text-upper {
    letter-spacing: 2px;
    text-transform: uppercase;
}
.button--text-thin {
    font-weight: 300;
}
.button--text-medium {
    font-weight: 500;
}
.button--text-thick {
    font-weight: 600;
}
.button--round-s {
    border-radius: 5px;
}
.button--round-m {
    border-radius: 15px;
}
.button--round-l {
    border-radius: 40px;
}

/* Borders */
.button--border-thin {
    border: 1px solid;
}
.button--border-medium {
    border: 2px solid;
}
.button--border-thick {
    border: 3px solid;
}

/* Winona */
.button--winona {
    overflow: hidden;
    padding: 0;
    -webkit-transition: border-color 0.3s, background-color 0.3s;
    transition: border-color 0.3s, background-color 0.3s;
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--winona::after {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    color: #3f51b5;
    -webkit-transform: translate3d(0, 25%, 0);
    transform: translate3d(0, 25%, 0);
}
.button--winona > span {
    display: block;
}
.button--winona.button--inverted {
    color: #7986cb;
}
.button--winona.button--inverted:after {
    color: #fff;
}
.button--winona::after,
.button--winona > span {
    /*padding: 1em 2em;*/
    padding: 4px 0;
    -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
    transition: transform 0.3s, opacity 0.3s;
    -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
}
.button--winona:hover:enabled,
.button--winona:focus:enabled {
    border-color: #3f51b5;
    background-color: rgba(63, 81, 181, 0.1);
}
.button--winona.button--inverted:hover:enabled,
.button--winona.button--inverted:focus:enabled {
    border-color: #21333C;
    background-color: #21333C;
}
.button--winona:hover:enabled::after,
.button--winona:focus:enabled::after {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.button--winona:hover:enabled > span,
.button--winona:focus:enabled > span {
    opacity: 0;
    -webkit-transform: translate3d(0, -25%, 0);
    transform: translate3d(0, -25%, 0);
}

.button--winona:disabled {
    color: #aaa;
}
