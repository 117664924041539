.MainPage {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.MainPage__Content {
    height: 100%;
    display: flex;
}

.SidebarContent {
    padding: 12px;
}
