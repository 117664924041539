.AnalysisSelectModal {
    display: flex;
    flex-direction: column;
    width: 800px;
    height: 600px;
}

.AnalysisSelectModal__analyses {
    margin: 8px 0;
    flex-grow: 1;
    overflow-y: auto;
}

.AnalysisSelectModal__control {
    padding-left: 8px;
}

.AnalysisSelectModal input[type="checkbox"] {
    margin-right: 8px;
}

/*.AnalysisSelectModal__analyses {
    display: flex;
    margin-bottom: 16px;
}

.AnalysisSelectModal__analyses__analysis {
    margin-left: 16px;
}

.AnalysisSelectModal__analyses__analysis label {
    cursor: pointer;
}

.AnalysisSelectModal .columnHeader {
    font-weight: bold;
    margin: 16px 0;
}*/
