#Control .controllerEquipment {
    border: 1px solid #9d9d9d;
    padding: 8px;
    width: 400px;
    margin-bottom: 8px;
}

#Control .endpoint {
    margin-bottom: 8px;
}
